import firebase from './firebase.data'

export const environment = {
  app_name: 'Biptt',
  production: true,
  onpremise: false,
  //baseUrl: 'https://production.biptt.com:8081',
  baseUrl: 'https://api.biptt.com.br/api/v2',
  //webSocketUrl: 'wss://production.biptt.com',
  webSocketUrl: 'wss://ws.biptt.com.br',
  sttUrl: 'https://notification.biptt.com.br:8081',
  imageUrl: 'https://image.biptt.com.br',
  registerUrl: 'https://biptt.com.br/signup/?utm_source=biptt-login-page&utm_medium=login&utm_campaign=login',
  identityPoolId: "us-east-2:44608e52-5a78-48d1-8fc8-eaf370a6086f",
  use_email_verification: false,
  use_rdstation: true,
  firebase: { ...firebase.firebase },
  usePhoneAuth: false,
  promoLeadUrl: 'https://materiais.biptt.com.br/landing-page-do-embaixador?bl_ref=Q05D5',
  emailValidatorUrl: 'https://emailvalidation.abstractapi.com/v1/?api_key=9ad78766486c405aab64bb0f764d5726'
};
